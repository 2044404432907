import styled, { css } from "styled-components";
import Link from "./Link";

export const BannerStyle = css`
  background-color: #B2E3D6;
  color: #646464;
  padding: 1rem;
  font-size: 1.2em;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  margin-top: 55px;
  z-index: 1;
  & + * {
    margin-top: 0 !important;
  }
`;

export const Banner = styled.div`
  ${BannerStyle}
`;

export const BookByMailBanner: React.FC<{
  activeUntil: Date;
}> = ({ activeUntil }) => {
  if (activeUntil < new Date()) {
    return;
  }

  const datum = activeUntil.toLocaleDateString("de-DE", { month: "long", year: "numeric" });
  return (
    <Banner>
      Ab {datum} nehme ich wieder Neukund:innen auf.
      Terminanfragen bitte per <Link href="mailto:info@praxis-lavandula.ch">Email</Link>.
    </Banner>
  );
};