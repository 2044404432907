import styled from "styled-components";
import { ReactComponent as logo } from "../assets/logo.svg";
import { ReactComponent as bigLogo } from "../assets/logo-big.svg";
import Burger from "./Burger";
import { DropdownMenu, Nav, NavItem, NavLinkStyled, NavList } from "./Nav";
import { useEffect, useState } from "react";
import { NavLinkProps } from "react-router-dom";
import { ColumnStyle } from "./Layout";
import { Banner } from "./Banner";

const HeaderWrapper = styled.header<{
  $isMobileHome?: boolean;
  $isScrolled?: boolean;
  $isMobileMenuOpen?: boolean;
}>`
   ${({ $isMobileHome, $isScrolled, $isMobileMenuOpen }) => {
    let style =
      `background-color: white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      ${Intro} {
        pointer-events: none;
        width: 45px;
        top: -38px;
        span, p {
          opacity: 0;
          visibility: hidden;
        }
        a {
          pointer-events: all;
          &.active {
            pointer-events: none;
          }
        }
      }`

    if ($isMobileHome) {
      if ($isScrolled) {
        style += `& + ${Banner} {
        margin-top: 0;
      }`
      } else {
        style = `& + div {
          margin-top: 300px;
        }
        nav {
          top: 0;
          height: 100svh;
          padding-top: 55px;
        }
        ${!$isMobileMenuOpen && `& > div:first-child {
          &::after, &::before, span {
            background-color: white;
          }
        }`}`
      }
    }

    return style
  }}

  & + div {
    transition: margin-top 0.5s ease-in-out;
  }
  transition: background-color 0.5s ease-in-out;
  z-index: 2;
  nav {
    z-index: 1;
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  height: 55px;
  align-items: center;
`;

const Logo = styled(logo)`
  height: 50px;
  fill: #9c77ff;
`;

const BigLogo = styled(bigLogo)`
  fill: #9c77ff;
  height: 100%;
  width: 100%;
`;

const Intro = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  ${ColumnStyle}
  color: #9c77ff;
  width: 150px;
  margin: 0 auto;
  transition: 0.5s ease-in-out;
  span,
  p {
    transition: 0.2s ease-in-out;
    width: 100vw;
    text-align: center;
    font-weight: bold;
  }
  span {
    font-size: 1.2em;
  }
`;

const BookNavItem = styled(NavItem)`
  a {
    display: flex;
    align-items: center;
    color: #9c77ff;

    ${Logo} {
      height: 30px;
      margin: 0 0 10px 10px;
    }

    &.active {
      font-weight: bold;
    }
  }
`;

const Header = ({ isHome }: { isHome?: boolean }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (isMobileMenuOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [isMobileMenuOpen]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [isScrolled, setIsScrolled] = useState(window.scrollY > 1);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 1);
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const NavAction: React.FC<NavLinkProps> = (props) => (
    <NavLinkStyled
      {...props}
      onClick={() => setMobileMenuOpen(false)}
      viewTransition
    />
  );

  return (
    <HeaderWrapper
      $isMobileHome={isHome && isMobile}
      $isScrolled={isScrolled}
      $isMobileMenuOpen={isMobileMenuOpen}
    >
      <Burger
        open={isMobileMenuOpen}
        onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
      />
      {!isMobile && (
        <NavAction className="button" to="/termin-buchen">
          Termin buchen
        </NavAction>
      )}
      <Nav open={isMobileMenuOpen}>
        <NavList>
          <NavItem>
            <NavAction to="/">Startseite</NavAction>
          </NavItem>
          <NavItem>
            <>Angebote</>
            <DropdownMenu>
              <NavList>
                <NavItem>
                  <NavAction to="/angebote/klassische-massage">
                    Klassische Massage
                  </NavAction>
                </NavItem>
                <NavItem>
                  <NavAction to="/angebote/fussreflexzonenmassage">
                    Fussreflexzonenmassage
                  </NavAction>
                </NavItem>
                <NavItem>
                  <NavAction to="/angebote/dorntherapie">
                    Dorntherapie
                  </NavAction>
                </NavItem>
                <NavItem>
                  <NavAction to="/angebote/naturheilkunde">
                    Naturheilkunde
                  </NavAction>
                </NavItem>
              </NavList>
            </DropdownMenu>
          </NavItem>
          <NavItem>
            <NavAction to="/ueber-mich">Über mich</NavAction>
          </NavItem>
          <NavItem>
            <NavAction to="/kontakt">Kontakt</NavAction>
          </NavItem>
          <NavItem>
            <NavAction to="/preise">Preise</NavAction>
          </NavItem>
          {isMobile && (
            <BookNavItem>
              <NavAction to="/termin-buchen">
                Termin Buchen
                <Logo />
              </NavAction>
            </BookNavItem>
          )}
        </NavList>
      </Nav>
      {isMobile ? (
        <Intro>
          <span>Willkommen in der</span>
          <NavAction to="/">
            <BigLogo />
          </NavAction>
          <p>für manuelle Therapie und Naturheilkunde</p>
        </Intro>
      ) : (
        <NavAction to="/">
          <Logo />
        </NavAction>
      )}
    </HeaderWrapper>
  );
};

export default Header;
