import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import { BookByMailBanner } from "./components/Banner";
import Footer from "./components/Footer";
import { useEffect } from "react";

// import pages
import HomePage from "./pages/Home";
import BookAppointmentPage from "./pages/BookAppointment";
import ClassicMassagePage from "./pages/ClassicMassage";
import FootReflexologyPage from "./pages/FootReflexology";
import DornTherapyPage from "./pages/DornTherapy";
import NaturopathyPage from "./pages/Naturopathy";
import AboutMePage from "./pages/AboutMe";
import ContactPage from "./pages/Contact";
import PricesPage from "./pages/Prices";
import LegalNoticePage from "./pages/LegalNotice";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";

const router = createBrowserRouter([
  {
    path: "/",
    Component: () => {
      const { pathname } = useLocation();
      useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, [pathname]);

      return (
        <>
          <Header isHome={pathname === "/"} />
          <BookByMailBanner activeUntil={new Date("2025-05-01")} />
          <Outlet />
          <Footer />
        </>
      );
    },
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "/termin-buchen",
        element: <BookAppointmentPage />,
      },
      {
        path: "/angebote/klassische-massage",
        element: <ClassicMassagePage />,
      },
      {
        path: "/angebote/fussreflexzonenmassage",
        element: <FootReflexologyPage />,
      },
      {
        path: "/angebote/dorntherapie",
        element: <DornTherapyPage />,
      },
      {
        path: "/angebote/naturheilkunde",
        element: <NaturopathyPage />,
      },
      {
        path: "/ueber-mich",
        element: <AboutMePage />,
      },
      {
        path: "/kontakt",
        element: <ContactPage />,
      },
      {
        path: "/preise",
        element: <PricesPage />,
      },
      {
        path: "/impressum",
        element: <LegalNoticePage />,
      },
      {
        path: "/datenschutzerklaerung",
        element: <PrivacyPolicyPage />,
      },
    ],
  },
]);

const App = () => (
  <RouterProvider router={router} future={{ v7_startTransition: true }} />
);
export default App;
